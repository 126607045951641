<template>
  <div
    v-if="organizations.length"
    class="organizations"
    :class="[
      { 'organizations--selected': selected }
    ]"
  >
    <h1 class="organizations__title text-center mt-n8 mb-8 text-light">
      Namens welke organisatie wil je inloggen?
    </h1>
    <div class="row justify-content-center">
      <div
        v-for="(organization, i) in organizations"
        :key="organization.id"
        class="organization d-flex col-3 py-3 cursor-pointer text-center fade"
        :class="[
          { 'organization--selected': organization.selected },
          { show: organization.visible },
        ]"
        @click="select(i)"
      >
        <div
          class="organization__container position-relative d-flex flex-column align-items-center
                 w-100 px-4 pt-6 pb-5 border-radius-lg"
        >
          <material-avatar
            :img="organization.logoUrl"
            :alt="`Beeldmerk van ${organization.name}`"
            circular
            size="xxl"
            spacing="20%"
          />
          <div
            lang="nl"
            class="organization__name mt-4 text-light cursor-pointer"
          >
            {{ organization.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getOrganizationAuth } from '@/api/providers/account';
import MaterialAvatar from '@/components/MaterialAvatar.vue';

export default {
  name: 'AccountOrganizations',

  components: {
    MaterialAvatar,
  },

  data: () => ({
    organizations: [],
    selected: false,
  }),

  computed: {
    ...mapState('account', {
      organizationsData: 'organizations',
    }),
  },

  mounted() {
    this.initOrganizations();
  },

  methods: {
    ...mapActions('account', [
      'setAuth',
    ]),

    initOrganizations() {
      if (this.organizationsData.length === 1) {
        this.setOrganization(this.organizationsData[0].id)
          .then(this.routeToDashboard);
      } else {
        for(let i = 0; i < this.organizationsData.length; i++) {
          const { id, logoUrl, name } = this.organizationsData[i];

          this.organizations.push({
            id,
            logoUrl,
            name,
            selected: false,
            visible: false,
          });

          setTimeout(() => {
            this.organizations[i].visible = true;
          }, 100 * (i + 1));
        }
      }
    },

    routeToDashboard() {
      this.$router.push({
        name: 'dashboard',
      });
    },

    select(index) {
      if (this.selected) {
        return;
      }

      this.selected = true;

      const id = this.organizations[index].id;
      const organizationSetter = this.setOrganization(id);

      this.organizations
        .filter((organization) => organization.id !== id)
        .forEach((organiastion) => {
          organiastion.visible = false;
        });
      this.organizations[index].selected = true;

      setTimeout(() => {
        organizationSetter
          .then(() => {
            this.$router.push({
              name: 'dashboard',
            });
          })
          .catch((error) => error.default());
      }, 800);
    },

    setOrganization(id) {
      return getOrganizationAuth(id)
        .then(({ auth }) => {
          this.setAuth(auth);
        });
    }
  },
};
</script>

<style scoped lang="scss">
.organization {
  transition-property: opacity, transform;
  transition-duration: .4s;
  transition-timing-function: ease-out;

  &:hover {
    transform: rotateY(0) translateY(-3%);
    transition-duration: .2s;
  }

  &:not(.show) {
    transform: translateX(20%);

    .organizations--selected & {
      transform: translateY(10%);
    }
  }

  &--selected {

    &,
    &:hover {
      transform: rotateY(1turn) translateY(0);
      transition-duration: .4s;
    }
  }

  &__container {
    border: 1px solid rgb(255 255 255 / .1);
    background-color: rgb(255 255 255 / .025);
    backdrop-filter: blur(10px);
  }

  &__name {
    hyphens: auto;
    word-break: break-word;
  }
}
</style>
